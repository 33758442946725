import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Avatar } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import Box from "@mui/material/Box";

export default function Customize() {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Customize
      </Typography>
      <Grid container spacing={3}>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          item
          xs={12}
          sm={6}
        >
          <Avatar
            alt="Remy Sharp"
            src="/static/images/avatar/1.jpg"
            sx={{ width: 56, height: 56 }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="OrganisationName"
            name="OrganisationName"
            label="Organisation Name"
            fullWidth
            autoComplete="family-name"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="OrganisationDetails"
            name="OrganisationDetails"
            label="Organisation Details"
            fullWidth
            autoComplete="OrganisationDetails"
            variant="standard"
          />
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Currency
              </InputLabel>
              <NativeSelect
                defaultValue={30}
                inputProps={{
                  name: "age",
                  id: "uncontrolled-native",
                }}
              >
                <option value={10}>Rupee</option>
                <option value={20}>Dollar</option>
                <option value={30}>Pound</option>
              </NativeSelect>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="clientType">
                Client Type
              </InputLabel>
              <NativeSelect
                defaultValue={1}
                inputProps={{
                  name: "clientType",
                  id: "clientType",
                }}
              >
                <option value={1}>Type 1</option>
                <option value={2}>Type 2</option>
                <option value={3}>Type 3</option>
              </NativeSelect>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="EmailForOTP"
            name="EmailForOTP"
            label="Email For OTP"
            fullWidth
            autoComplete="EmailForOTP"
            variant="standard"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
