import * as React from "react";
import { styled } from "@mui/system";
import { Tabs } from "@mui/base/Tabs";
import { TabsList as BaseTabsList } from "@mui/base/TabsList";
import { TabPanel as BaseTabPanel } from "@mui/base/TabPanel";
import { buttonClasses } from "@mui/base/Button";
import { Tab as BaseTab, tabClasses } from "@mui/base/Tab";
import { Box, TextField, Typography, Grid } from "@mui/material";

export default function AdminFirstLogin() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ width: "50%", border: "2px solid red" }}>
        <Tabs defaultValue={0}>
          <TabsList>
            <Tab value={0}>Add Company</Tab>
            <Tab value={1}>Add Branch</Tab>
            <Tab value={2}>Add Bank</Tab>
          </TabsList>
          <TabPanel
            style={{
              backgroundColor: "white",
              //   border: "2px solid green",
              opacity: "1",
            }}
            value={0}
          >
            {" "}
            <Typography variant="h6" gutterBottom>
              Personal details
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="CompName*"
                  name="CompName*"
                  label="Company name"
                  fullWidth
                  autoComplete="company name"
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="CompShortName*"
                  name="CompShortName*"
                  label="Company Short Name"
                  fullWidth
                  autoComplete="company short name"
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="CompShortName*"
                  name="CompShortName*"
                  label="Company Short Name"
                  fullWidth
                  autoComplete="company short name"
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="CompOwnerName*"
                  name="CompOwnerName*"
                  label="Company Owner Name"
                  fullWidth
                  autoComplete="company owner name"
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="CompShortName*"
                  name="CompShortName*"
                  label="Company Short Name"
                  fullWidth
                  autoComplete="company short name"
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="CompShortName*"
                  name="CompShortName*"
                  label="Company Short Name"
                  fullWidth
                  autoComplete="company short name"
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="CompShortName*"
                  name="CompShortName*"
                  label="Company Short Name"
                  fullWidth
                  autoComplete="company short name"
                  variant="standard"
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={1}>Branch</TabPanel>
          <TabPanel value={2}>Bank</TabPanel>
        </Tabs>
      </Box>
    </Box>
  );
}

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#80BFFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Tab = styled(BaseTab)`
  font-family: "IBM Plex Sans", sans-serif;
  color: #fff;
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: 600;
  background-color: transparent;
  width: 100%;
  padding: 10px 12px;
  box-sizing: border-box;
  margin: 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${blue[400]};
  }

  &:focus {
    color: #fff;
    outline: 3px solid ${blue[200]};
  }

  &.${tabClasses.selected} {
    background-color: #fff;
    color: ${blue[600]};
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(BaseTabPanel)(
  ({ theme }) => `
      width: 100%;
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 0.875rem;
      box-sizing:border-box;
      padding: 20px 10px;
      background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border: 1px solid ${
        theme.palette.mode === "dark" ? grey[700] : grey[200]
      };
      border-radius: 12px;
      opacity: 0.6;
      `
);

const TabsList = styled(BaseTabsList)(
  ({ theme }) => `
      min-width: 150px;
      background-color: ${blue[500]};
      border-radius: 12px;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing:border-box;
      align-content: space-between;
      box-shadow: 0px 4px 30px ${
        theme.palette.mode === "dark" ? grey[900] : grey[200]
      };
      `
);
